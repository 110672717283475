import React, { CSSProperties, useState } from "react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  ColumnDef,
  Column,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Box, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Filters from "../share/Filter";
import FilterListIcon from "@mui/icons-material/FilterList";

type PropsDataTable<T> = {
  columns: ColumnDef<T>[]; // Columnas de la tabla
  data: T[]; // Datos de la tabla
};

type ColumnFilter = {
  id: string;
  value: string;
};

export const DataTableDashboard = <T extends object>({
  columns,
  data,
}: PropsDataTable<T>) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [openFilterColumn, setOpenFilterColumn] = useState<string | null>(null);
  const headerRefs = React.useRef<Record<string, HTMLTableHeaderCellElement>>({});

  // Configuración de React Table
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: "onChange",


  });

  // Estilos comunes para las columnas "sticky"
  const getCommonPinningStyles = (column: Column<T>, isHeader: boolean): CSSProperties => {

    const allColumns = table.getAllColumns();

    // Encuentra el índice de la columna actual
    const columnIndex = allColumns.findIndex((col) => col.id === column.id);

    const leftOffset = allColumns
      .slice(0, columnIndex) // Todas las columnas anteriores
      .reduce((offset, col) => (col.getIsVisible() ? offset + col.getSize() : offset), 0);


    if (column.id === "producer_name") {
      return {
        position: "sticky",
        left: leftOffset,
        zIndex: isHeader ? 3 : 2, // Mayor z-index para encabezado, menor para celdas
        background: "#fff",
        width: column.getSize(), // Mantén el ancho sincronizado

      };
    }

    if (column.id === "crop") {
      return {
        position: "sticky",
        left: leftOffset,
        zIndex: isHeader ? 3 : 2, // Mayor z-index para encabezado, menor para celdas
        background: "#fff",
        width: column.getSize(), // Mantén el ancho sincronizado

      };
    }

    if (column.id === "farm_name") {
      return {
        position: "sticky",
        left: leftOffset,
        zIndex: isHeader ? 3 : 2, // Mayor z-index para encabezado, menor para celdas
        background: "#fff",
        width: column.getSize(), // Mantén el ancho sincronizado

      };
    }



    return {};
  };;

  return (
    <div >
      {/* <Filters columnFilters={columnFilters} setColumnFilters={setColumnFilters} /> */}
      <div style={{ direction: table.options.columnResizeDirection }}>
        <div className="table-container" style={{ maxHeight: "calc(100vh - 170px)", overflowY: "auto", }}>
          <table
            style={{
              width: table.getTotalSize(),
            }}

          >
            <thead className="header">
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    const { column } = header

                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                          ...getCommonPinningStyles(column, true),
                          backgroundColor: "#005f40",
                        }}
                        ref={(el) => {
                          if (el) headerRefs.current[column.id] = el;
                        }}
                      >

                        <div key={`header_content_${header.id}`} style={{ display: "flex", alignItems: "center", gap: "4px", padding: "0 8px" }}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}




                          <Box key={`filter_box_${header.id}`} display="flex" sx={{ alignItems: "center", gap: "4px" }} >
                            <IconButton
                              key={`search_icon_${header.id}`}
                              size="small"
                              onClick={() => setOpenFilterColumn((prev) => (prev === column.id ? null : column.id))}
                              sx={{
                                marginLeft: "4px",
                                color: columnFilters.some(
                                  (filter) => filter.id === header.column.id && filter.value.trim() !== ""
                                )
                                  ? "#fff"
                                  : "#ccc",


                                "& svg": { fontSize: "1.3rem" },
                              }}
                            >
                              {columnFilters.some(
                                (filter) => filter.id === header.column.id && filter.value.trim() !== ""
                              ) ? (
                                // Ícono de "filtro activo"
                                <FilterListIcon />
                              ) : (
                                // Ícono de "búsqueda"
                                <SearchIcon />
                              )}
                            </IconButton>


                            <Box key={`sort_buttons_${header.id}`} display="flex" flexDirection="column" alignItems="center">
                              <IconButton
                                key={`sort_asc_${header.id}`}
                                onClick={() => setSorting([{ id: column.id, desc: false }])}
                                sx={{
                                  padding: 0,
                                  color: sorting.some((s) => s.id === header.column.id && !s.desc) ? "#8c8b8b" : "#ccc",
                                }}
                              >

                                <span
                                  style={{
                                    fontSize: "12px", // Ajusta el tamaño aquí
                                    lineHeight: "1",
                                  }}
                                >
                                  ▲
                                </span>
                              </IconButton>
                              <IconButton
                                key={`sort_desc_${header.id}`}
                                onClick={() => setSorting([{ id: column.id, desc: true }])}
                                sx={{
                                  padding: 0,
                                  color: sorting.some((s) => s.id === header.column.id && s.desc) ? "#8c8b8b" : "#ccc",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "12px", // Ajusta el tamaño aquí
                                    lineHeight: "1",
                                  }}
                                >
                                  ▼
                                </span>
                              </IconButton>
                            </Box>
                          </Box>


                          <Filters
                            key={`box_${header.id}`}
                            isOpen={openFilterColumn === column.id}
                            onClose={() => setOpenFilterColumn(null)}
                            anchorRef={{ current: headerRefs.current[column.id] }}
                            columnFilters={columnFilters}
                            setColumnFilters={setColumnFilters}
                            type={column.id}

                            width={header.getSize()}
                          />
                        </div>
                        <div
                          key={`resizer_${header.id}`}
                          onDoubleClick={() => header.column.resetSize()}
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                        />

                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    const { column } = cell
                    return (
                      <td
                        key={cell.id}
                        style={{ ...getCommonPinningStyles(column, false) }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};
