import { Box, TextField, InputAdornment, MenuItem, Select } from "@mui/material";
import ReactDOM from "react-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useRef } from "react";

const Filters = ({
    columnFilters,
    setColumnFilters,
    type,
    width,
    anchorRef,
    isOpen,
    onClose,
}: any) => {
    const filterValue = columnFilters.find((f: any) => f.id === type)?.value || "";
    const filterRef = useRef<HTMLDivElement>(null);

    // Actualiza el filtro
    const onFilterChange = (id: any, value: any) => {
        setColumnFilters((prev: any) =>
            prev
                .filter((f: any) => f.id !== id)
                .concat({
                    id,
                    value,
                })
        );
    };

    // Maneja el evento de presionar Enter en el input
    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            onClose();
        }
    };

    // Maneja el cambio en el select
    const handleSelectChange = (e: any) => {
        onFilterChange(type, e.target.value);
        onClose();
    };

    // Maneja el clic fuera del input
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // Solo aplicar para inputs de texto, no para selects
            if (type === "name" || type === "email" || type === "producer_name" || type === "crop" ||
                type === "total_ha" || type === "sowed_percentage" || type === "latest_sowing_date" ||
                type === "risk_percentage" || type === "estimated_yield" || type === "estimated_yield_unit" ||
                type === "harvested_percentage" || type === "latest_harvest_date" || type === "farm_name" ||
                type === "borrower_name" || type === "fira_borrower_id" || type === "state_names" ||
                type === "municipality_names" || type === "season_cycle" || type === "fira_credit_id" ||
                type === "financed_amount" || type === "financed_amount_per_ha" || type === "financed_ha") {
                if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
                    onClose();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose, type]);

    if (!isOpen || !anchorRef.current) return null;

    // Calcula la posición del filtro basado en la referencia del encabezado
    const anchorRect = anchorRef.current.getBoundingClientRect();

    return ReactDOM.createPortal(
        <Box
            ref={filterRef}
            sx={{
                position: "absolute",
                top: anchorRect.bottom + window.scrollY,
                left: anchorRect.left + window.scrollX,
                zIndex: 1000,
                bgcolor: "white",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                p: 1,
                borderRadius: "4px",
                width: `${Math.min(Math.max(200, width), 300)}px`,
                display: "flex",
                flexDirection: "column",
            }}
        >
            {/* Input de buscar para strings */}
            {type === "name" || type === "email" || type === "producer_name" || type === "crop" || type === "total_ha" || type === "sowed_percentage" || type === "latest_sowing_date" || type === "latest_sowing_date" || type === "risk_percentage" || type === "estimated_yield" || type === "estimated_yield_unit" || type === "harvested_percentage"
                || type === "latest_harvest_date" || type === "farm_name" || type === "borrower_name" || type === "fira_borrower_id" || type === "state_names" || type === "municipality_names" || type === "crop" || type === "season_cycle" || type === "fira_credit_id" || type === "financed_amount" || type === "financed_amount_per_ha" || type === "financed_ha" ? (
                <Box display="flex" alignItems="center" margin="2%">
                    <TextField
                        size="small"
                        variant="outlined"
                        value={filterValue}
                        onChange={(e: any) => onFilterChange(type, e.target.value)}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ marginLeft: "4px" }} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                padding: 1,
                            },
                        }}
                    />
                </Box>
            ) : (
                // Select para booleanFilter (activos/inactivos)
                <Box display="flex" alignItems="center" margin="2%">
                    <Select
                        value={filterValue}
                        onChange={handleSelectChange}
                        displayEmpty
                        fullWidth
                        size="small"
                        sx={{
                            "& .MuiSelect-select": { padding: "8px", fontSize: ".9rem" },
                        }}
                    >
                        <MenuItem value="" sx={{ fontSize: ".9rem" }}>Mostrar todos</MenuItem>
                        <MenuItem value="activos" sx={{ fontSize: ".9rem" }}>Activos</MenuItem>
                        <MenuItem value="inactivos" sx={{ fontSize: ".9rem" }}>Inactivos</MenuItem>
                    </Select>
                </Box>
            )}
        </Box>,
        document.body
    );
};

export default Filters;
